import React from "react";
import classNames from "classnames";

import { AppLink } from "@base";
import Icon from "./Icon";

const Share = ({ title, url, className: _className }) => {
  const urls = {
    LinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`,
    Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    Facebook: `https://www.facebook.com/dialog/share?app_id=${
      process.env.GATSBY_FACEBOOK_APP_ID
    }&display=page&href=${encodeURIComponent(
      url
    )}&redirect_uri=${encodeURIComponent(`https://www.facebook.com`)}`,
  };

  const classes =
    "flex h-7 w-7 items-center justify-center rounded-full bg-carnation p-2 duration-300 hover:bg-midnight";

  return (
    <div className={classNames("flex items-center space-x-4", _className)}>
      {title && (
        <span className="font-heading text-lg text-carnation">{title}</span>
      )}
      <AppLink to={urls.LinkedIn} target="_blank" className={classes}>
        <Icon name="linkedin" className="h-full w-full text-white" />
      </AppLink>
      <AppLink to={urls.Twitter} target="_blank" className={classes}>
        <Icon name="twitter" className="h-full w-full text-white" />
      </AppLink>
      <AppLink to={urls.Facebook} target="_blank" className={classes}>
        <Icon name="facebook" className="h-full w-full text-white" />
      </AppLink>
    </div>
  );
};

Share.defaultProps = {
  title: "Share:",
};

export default Share;
