import React, { Suspense, useRef, useEffect } from "react";
import "../../pcss/video.pcss";
import Image from "./Image";

const Video = ({ url: _url, muted, autoplay, placeholder, play }) => {
  let url;
  const plyr = useRef();
  const player = useRef();
  const options = {
    muted,
    autoplay,
  };
  const provider = _url.match("http(s)?://(www.)?youtube|youtu.be")
    ? "youtube"
    : "vimeo";

  if (provider === "youtube") {
    if (_url.match("embed")) {
      [url] = _url.split(/embed\//)[1].split('"');
    } else {
      [url] = _url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/);
    }
  }

  if (provider === "vimeo") {
    url = _url.split("/").pop();
  }

  useEffect(() => {
    if (player.current) {
      if (play) {
        player.current.play();
      } else {
        player.current.pause();
      }
    }
  }, [play]);

  useEffect(() => {
    // eslint-disable-next-line global-require
    const Plyr = require("plyr");

    setTimeout(() => {
      if (!player.current) {
        if (plyr.current && Plyr) {
          player.current = new Plyr(plyr.current);
          if (!play) {
            player.current.pause();
          }
        }
      }
    }, 500);
  }, []);
  // set up the player
  return (
    <Suspense
      fallback={
        <div>
          <Image image={placeholder} />
        </div>
      }
    >
      <div
        data-plyr-provider={provider}
        data-plyr-embed-id={url}
        ref={plyr}
        data-plyr-config={JSON.stringify(options)}
      />
    </Suspense>
  );
};

Video.defaultProps = {
  play: true,
};

export default Video;
