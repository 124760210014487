import React, { useState } from "react";
import classNames from "classnames";
import {
  InputText,
  InputTextarea,
  InputCheckbox,
  InputRadio,
  InputSelect,
  InputFile,
} from "@base";
import Text from "./Text";

const Input = ({
  label,
  hideLabel,
  type,
  placeholder,
  name,
  singleLine,
  formState,
  errorState,
  errorMessage,
  className: _className,
  value,
  required,
  options,
  id,
  group,
  inverse,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error] = errorState || useState([]);
  const hasError = error?.includes(name);
  const [checkedList, setCheckedList] = useState([]);

  let Field;
  switch (type) {
    case "text":
      Field = InputText;
      break;
    case "email":
      Field = InputText;
      break;
    case "textarea":
      Field = InputTextarea;
      break;
    case "checkbox":
      Field = InputCheckbox;
      break;
    case "radio":
      Field = InputRadio;
      break;
    case "select":
      Field = InputSelect;
      break;
    case "file":
      Field = InputFile;
      break;
    default:
      Field = InputText;
      break;
  }

  // Only have the group work with checkboxes and radio buttons
  // If wanting to have multiple <Input/>s, use a <FieldSet /> as a container:
  // <FieldSet>
  //   <Input>
  //   <Input>
  //   <Input>
  // </FieldSet>
  if (group?.length && (type === "checkbox" || type === "radio")) {
    return (
      <fieldset className={classNames(_className)}>
        {label && (
          <legend className={classNames("mb-2", { "sr-only": hideLabel })}>
            <span className="block font-sans font-bold uppercase tracking-1">
              {label} {required && <span className="text-white">*</span>}
            </span>
          </legend>
        )}
        {group?.map((_g, i) => {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${id}-${name}--${i}`}
              className={classNames({
                "mb-2 flex items-center": singleLine || _g?.singleLine,
                "mb-2 flex flex-row-reverse items-center justify-end":
                  type === "checkbox" || type === "radio",
                "custom-select": type === "select",
              })}
            >
              <label
                htmlFor={_g?.id || name}
                className={classNames(
                  "block cursor-pointer font-sans font-bold",
                  {
                    "mb-2":
                      !singleLine && type !== "checkbox" && type !== "radio",
                    "ml-2": type === "checkbox" || type === "radio",
                    "mr-2": singleLine || _g?.singleLine,
                  }
                )}
              >
                {_g?.label}
              </label>
              <Field
                inverse={inverse}
                {..._g}
                id={_g?.id || name}
                name={name || _g?.name}
                value={_g.value}
                formState={[form, setForm]}
                hasError={hasError}
                groupState={
                  type === "checkbox" ? [checkedList, setCheckedList] : null
                }
                className=""
              />
            </div>
          );
        })}
        {errorMessage && hasError && (
          <Text variant="xs" className="mt-1 text-white">
            {errorMessage}
          </Text>
        )}
      </fieldset>
    );
  }

  return (
    <div className={classNames(_className)}>
      <div
        className={classNames("relative flex h-full", {
          "items-center": singleLine,
          "flex-row-reverse items-center justify-end":
            type === "checkbox" || type === "radio",
          "flex-wrap justify-between": type !== "checkbox" && type !== "radio",
          "custom-select": type === "select",
          "text-white": hasError,
        })}
      >
        <label
          htmlFor={id || name}
          className={classNames(
            "block w-full font-sans font-bold uppercase tracking-1",
            {
              "mb-2": !singleLine && type !== "checkbox" && type !== "radio",
              "mr-2": singleLine,
              "ml-2": type === "checkbox" || type === "radio",
              "sr-only": hideLabel,
            }
          )}
        >
          <span>
            {label} {required && <span className="text-white">*</span>}
          </span>
        </label>

        <Field
          name={name}
          id={id || name}
          value={value}
          placeholder={placeholder}
          formState={[form, setForm]}
          hasError={hasError}
          options={options}
          inverse={inverse}
        />
      </div>
      {errorMessage && hasError && (
        <Text variant="xs" className="mt-1 text-white">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

Input.defaultProps = {};

export default Input;
