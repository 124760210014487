import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import Icon from "./Icon";

const Wrapper = ({ children, className: _className, condition, onClick }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink
          to={condition}
          onClick={onClick}
          className={classNames(_className)}
        >
          {children}
        </AppLink>
      ) : (
        <div className={classNames(_className)}>{children}</div>
      )}
    </>
  );
};

const colors = {
  midnight: "text-midnight",
  carnation: "text-carnation",
  mint: "text-mint",
  white: "text-white",
};

const ButtonAlt = ({
  children,
  className: _className,
  color,
  icon,
  onClick,
  reverse,
  to,
}) => {
  return (
    <Wrapper
      condition={to}
      onClick={onClick}
      className={classNames(
        "group inline-flex items-center",
        colors[color],
        _className
      )}
    >
      {/* back arrow */}
      {icon && reverse && (
        <span
          className={classNames(
            "mr-1 h-auto w-8 flex-shrink-0 rotate-180 transform duration-300 group-hover:-translate-x-2"
          )}
        >
          <Icon name={icon} />
        </span>
      )}
      {/* label */}
      <span className="font-sans font-bold uppercase leading-normal tracking-wide duration-300">
        {children}
      </span>
      {/* forward arrow */}
      {icon && !reverse && (
        <span className="ml-1 h-auto w-8 flex-shrink-0 duration-300 group-hover:translate-x-2">
          <Icon name={icon} />
        </span>
      )}
    </Wrapper>
  );
};

ButtonAlt.defaultProps = {
  color: "carnation",
  icon: "arrow",
  reverse: false,
};

export default ButtonAlt;
