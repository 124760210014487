import React, { useRef } from "react";
import classNames from "classnames";
import { useDynamicSVGImport } from "@utils";

const Icon = React.memo(({ name, className: _className, encode }) => {
  const icon = useRef();
  const { SvgIcon, loading } = useDynamicSVGImport(name, { encode });
  return (
    <span
      ref={icon}
      className={classNames("icon flex fill-current", _className)}
    >
      {!encode && !loading && SvgIcon && <SvgIcon />}
      {encode && !loading && SvgIcon && (
        <img alt={name} className="block h-full w-full" src={SvgIcon} />
      )}
    </span>
  );
});

Icon.defaultProps = {};

export default Icon;
