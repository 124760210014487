import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";

const query = graphql`
  query DefaultsQuery {
    craft {
      globalSet(handle: "defaults") {
        ... on Craft_defaults_GlobalSet {
          # cta
          ctaCopy: copy0
          ctaLink: link0 {
            text
            url
          }
          #form
          formHeading: heading1
          formDescriptor: descriptor1
          # meta
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const {
    ctaCopy,
    ctaLink,
    formHeading,
    formDescriptor,
    metaTitle,
    metaImage,
  } = globalSet;
  return {
    cta: {
      copy: ctaCopy,
      link: ctaLink,
    },
    form: {
      heading: formHeading,
      descriptor: formDescriptor,
    },
    meta: {
      title: metaTitle,
      metaImage: resolveImage(metaImage),
    },
  };
};

const useDefaultsData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useDefaultsData;
