import React from "react";
import classNames from "classnames";

const Toggle = ({
  id,
  name,
  value,
  label,
  checked,
  onClick,
  onChange,
  className: _className,
}) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex justify-center">
        <label htmlFor={id} className="relative inline-flex items-center">
          <input
            type="checkbox"
            name={name}
            id={id || name}
            value={value}
            className="peer sr-only"
            checked={checked}
            onClick={onClick}
            onChange={onChange}
          />
          <div
            className={classNames(
              "peer h-6 w-10 cursor-pointer rounded-full border-2 border-gray duration-300 after:absolute after:top-1 after:left-1 after:h-4 after:w-4 after:rounded-full after:transition-all after:content-[''] peer-checked:after:translate-x-full",
              _className
            )}
          />
          <span className="ml-2 text-sm">{label}</span>
        </label>
      </div>
    </div>
  );
};

Toggle.defaultProps = {};

export default Toggle;
