import React, { useMemo } from "react";
import useCraftPreview from "@hooks/useCraftPreview";
import Meta from "./Meta";

export const HeadTemplate = React.memo(
  ({ data, eagerUrls }) => {
    const { meta } = data;
    return (
      <>
        {/* <link
          rel="preload"
          href="/fonts/Raisonne-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
        <link
          rel="preload"
          href="/fonts/RaisonneTrial-BoldItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        /> */}
        {/* google fonts */}
        <link
          id="gfont1"
          rel="preconnect"
          href="https://fonts.googleapis.com"
        />
        <link
          id="gfont2"
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          id="gfont3"
          href="https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@600;800&display=swap"
          rel="stylesheet"
        />
        {/* other fonts */}
        <link
          id="giorgio1"
          rel="preload"
          href="/fonts/GiorgioSans-Medium.woff2"
          as="font"
        />
        <link
          id="giorgio2"
          rel="preload"
          href="/fonts/GiorgioSans-MediumItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
        <link
          id="giorgio3"
          rel="preload"
          href="/fonts/GiorgioSans-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
        <link
          id="giorgio4"
          rel="preload"
          href="/fonts/GiorgioSans-BoldItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
        {/* <link
          rel="preload"
          href="/fonts/ShipporiMincho-SemiBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        />
        <link
          rel="preload"
          href="/fonts/ShipporiMincho-ExtraBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="true"
        /> */}
        {eagerUrls &&
          eagerUrls.map(url => <link rel="preload" as="image" href={url} />)}
        <Meta {...meta} />
      </>
    );
  },
  () => true
);

const QueryContainer = ({
  data: _data,
  pageContext,
  location,
  template: Template,
  dataResolver,
  queryName,
}) => {
  const pageQuery = JSON.parse(process.env.GATSBY_QUERIES)[queryName];
  // get preview data if a preview
  const { data, isPreview } = useCraftPreview({
    data: _data?.craft,
    query: pageQuery,
    variables: { ...(pageContext || {}), uri: location.pathname.slice(1) },
  });
  // memoize based on whether or it is previewData (and only process data once!)
  const resolvedData = useMemo(
    () => (data ? dataResolver(data) : false),
    [isPreview]
  );
  // return the page component
  if (resolvedData) {
    return (
      <Template
        data={resolvedData}
        location={location}
        pageContext={pageContext}
      />
    );
  }
  return (
    <div className="font-medum bg-purple fixed inset-0 z-[9999] flex h-full w-full flex-grow items-center justify-center text-xl text-white">
      <div className="animate-pulse">Preview Loading...</div>
    </div>
  );
};

export default QueryContainer;
